import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})

export class ShoppingService {

  constructor(
    private http: HttpClient) {
  }

  translateSearchText(value: string): Observable<any> {
      return this.http.get<any>('https://translate.googleapis.com/translate_a/single?client=gtx&sl=tr&tl=en&dt=t&q=' + value);
  }

  getSeoText(brandName: string): Observable<any> {
    return this.http.get<any>(environment.api + 'brands/' + brandName + '?includeDetails=true');
}

  translateDescription(value: string): Observable<any> {
    return this.http.get<any>('https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=tr&dt=t&q=' + value);
}

  getProducts(value: string): Observable<any> {
      return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/products?pid=uid2500-1150750-18&format=json&' + value);
  }

  getBrands(): Observable<any> {
    return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/brands?pid=uid2500-1150750-18');
}

  getSingleProduct(value: string): Observable<any> {
    return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/products/' + value + '?pid=uid2500-1150750-18&format=json&');
}

  getHighlightedProducts(params?:string): Observable<any> {
    //return this.http.get<any>(environment.api + 'getproducts?parameters=products?pid=uid2500-1150750-18%26format=json%26limit=10%26fl=p6%26fl=p7%26fl=p8%26fl=p9%26fl=p10%26fl=p11%26fl=p12%26fl=p13%26fl=p14%26fl=p15%26fl=p16%26offset=0');
    if(params && params != '') {
      return this.http.get<any>(environment.api + params);
    } else {
      return this.http.get<any>(environment.api + 'getproducts?parameters=products?pid=uid2500-1150750-18%26format=json%26limit=12%26offset=0');
    }
   
  }

  setfavouriteProduct(param: any): Observable<any> {
    return this.http.post<any>(environment.api + 'favourite/add', param);
  }

  getfavouriteList(): Observable<any> {
    return this.http.get<any>(environment.api + 'favourite/list');
  }

  getBrandDetail(brandName:string): Observable<any> {
    return this.http.get<any>(environment.api + 'brands/' + brandName)
  }

  checkStock(productId:number): Observable<any> {
    return this.http.get<any>(environment.api + 'products/checkstock/' + productId)
  }

  searchPeek(param:any): Observable<any> {
    return this.http.get<any>(environment.api + 'search/peek/',param)
  }

  productPeek(param:any): Observable<any> {
    return this.http.get<any>(environment.api + 'product/peek',param)
  }

  getRecommendedProducts(categoryId:string): Observable<any> {
    return this.http.get<any>(environment.api + 'products?pid=uid6436-9095294-49&format=json&limit=' + 20 + '&cat=' + categoryId + '&offset=0')
  }
}
